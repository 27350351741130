import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'

// import { create } from 'zustand'
// interface TeamsState {
//   integrations: IntegrationItem[]
// }
// export const useIntegrationsStore = create<TeamsState>(() => ({
//   integrations: [],
// }))

export enum IntegrationStatus {
  InProgress = 'in_progress',
  Success = 'success',
  Failed = 'failed',
}

export interface IntegrationList {
  data: IntegrationItem[]
  meta: {
    status: string
    total: number
  }
}

export interface IntegrationItem {
  id: string
  type: string
  attributes: {
    id: string
    created_at: string
    name: string
    gitlab_endpoint: string
    gitlab_token: string
    gitlab_groups: string
    gitlab_projects: string
    sync_enabled: boolean
    sync_period: number
    last_sync: string
    last_sync_status: IntegrationStatus
    last_sync_error: string
  }
}

export const useIntegrationsList = (page = 1, perPage = 100) => {
  return useQuery({
    queryKey: ['integrations', page],
    queryFn: () => {
      return baseFetch<IntegrationList, 'json'>('/integrations', {
        query: {
          page,
          page_size: perPage,
        },
      })
    },
    placeholderData: keepPreviousData,
    refetchInterval(query) {
      if (
        query.state.data &&
        query.state.data.data.some(
          (item) => item.attributes.last_sync_status === 'in_progress'
        )
      ) {
        return 5000
      } else {
        return false
      }
    },
  })
}

export const useGetIntegration = (id?: string) => {
  return useQuery({
    queryKey: ['integration', id ?? null],
    queryFn: async () => {
      if (!id) {
        return null
      }
      return (
        await baseFetch<{ data: IntegrationItem }, 'json'>(
          `/integrations/${id}`
        )
      ).data
    },
  })
}

export const useStartSync = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id: string) => {
      return await baseFetch<{ data: IntegrationItem }, 'json'>(
        `/integrations/${id}/start_sync`,
        {
          method: 'GET',
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integrations'] })
    },
  })
}

export const useCheckIntegration = () => {
  return useMutation({
    mutationFn: async (attributes: Partial<IntegrationItem['attributes']>) => {
      return await baseFetch<
        { status: 'success' | 'failed'; details: string },
        'json'
      >('/integrations/check-connection', {
        method: 'POST',
        body: attributes,
      })
    },
  })
}

export const useUpdateIntegration = (id?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (attributes: Partial<IntegrationItem['attributes']>) => {
      if (!id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: IntegrationItem }, 'json'>(
        `/integrations/${id}`,
        {
          method: 'PUT',
          body: attributes,
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integration', id] })
      queryClient.invalidateQueries({ queryKey: ['integrations'] })
    },
  })
}

export const useDeleteIntegration = (id?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      if (!id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: IntegrationItem }, 'json'>(
        `/integrations/${id}`,
        {
          method: 'DELETE',
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integrations'] })
    },
  })
}

export const useCreateIntegration = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (attributes: Partial<IntegrationItem['attributes']>) => {
      await baseFetch<{ data: IntegrationItem }, 'json'>('/integrations', {
        method: 'POST',
        body: attributes,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integrations'] })
    },
  })
}
