import { NavLink, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function SettingsPage() {
  const { t } = useTranslation()

  const tabs = [
    {
      route: 'integrations',
      text: t('settings.integrations'),
    },
    {
      route: 'teams',
      text: t('settings.teams'),
    },
    {
      route: 'projects',
      text: t('settings.projects'),
    },
    {
      route: 'mappings',
      text: t('settings.mappings'),
    },
  ]

  return (
    <div className="flex flex-col p-10 grow min-w-fit">
      <div className="font-bold text-3xl">{t('settings.title')}</div>
      <div className="w-full mt-8 bg-gray-50 grow flex flex-col">
        <div className="bg-gray-50 pb-10 self-start">
          {tabs.map((item) => (
            <NavLink
              key={item.route}
              to={item.route}
              className={({ isActive }) =>
                [
                  isActive ? 'border-b-2 border-b-primary' : '',
                  'px-3 py-3 hover:opacity-85 transition-opacity',
                ].join(' ')
              }
            >
              {item.text}
            </NavLink>
          ))}
        </div>
        <div className="p-8 rounded-xl bg-white grow">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
