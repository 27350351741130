import { Outlet } from 'react-router-dom'
import { Toaster } from '@/components/ui/toaster'

import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function RootLayout() {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('user_verification') === 'success') {
      toast({
        title: t('login.user_verification_success'),
      })

      searchParams.delete('user_verification')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams, toast, t])
  return (
    <div className="root-layout">
      <Outlet />
      <Toaster />
    </div>
  )
}
