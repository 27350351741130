import { NavLink, useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
// import { Circle } from 'lucide-react'
import { Loader2 } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import { z } from 'zod'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useReset } from '@/store/auth'
import { useTranslation } from 'react-i18next'
import IconLogo from '@/components/icons/logo'

export default function LoginPage() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const reset = useReset()
  const { toast } = useToast()

  const formSchema = z.object({
    email: z
      .string()
      .min(2, {
        message: t('login.validation_email'),
      })
      .email({
        message: t('login.validation_email'),
      }),
  })

  type FormSchemaKeys = keyof z.infer<typeof formSchema>
  const keysArray: FormSchemaKeys[] = Object.keys(
    formSchema.shape
  ) as FormSchemaKeys[]

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  interface ValidationError {
    detail: string
    title: string
    source: {
      pointer: string
    }
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    reset.mutate(values, {
      onSuccess() {
        toast({
          title: t('login.reset_success'),
        })
        navigate('/login', { replace: true })
      },
      onError(error: any) {
        if (
          error.response &&
          error.response.status === 422 &&
          error.response._data?.errors
        ) {
          const errorData = error.response._data.errors as ValidationError[]

          errorData.forEach((error) => {
            const fieldName = error.source.pointer
              .split(/[./]/)
              .pop() as FormSchemaKeys
            if (keysArray.includes(fieldName)) {
              form.setError(fieldName, {
                message: error.detail,
              })
            }
          })
        } else {
          toast({
            title: t('login.reset_fail'),
            variant: 'destructive',
          })
        }
      },
    })
  }

  return (
    <div className="flex bg-white h-screen">
      <div className="lg:w-[60%] hidden lg:block bg-[url('/login-bg.jpg')] bg-no-repeat bg-cover bg-center p-8"></div>
      <div className="p-10 lg:w-[40%] w-full flex gap-12 flex-col items-center bg-gray-50">
        <div className="flex-grow flex flex-col items-center justify-center gap-24 w-full max-w-md">
          <div className="flex flex-col justify-center items-center gap-4 ">
            <Select
              onValueChange={i18n.changeLanguage}
              defaultValue={i18n.language}
            >
              <SelectTrigger className="border-none w-auto absolute top-6 right-6 text-lg bg-transparent">
                {i18n.language.toUpperCase()}
              </SelectTrigger>
              <SelectContent className="min-w-[6rem]">
                <SelectGroup>
                  {['en', 'ru'].map((lang) => {
                    return (
                      <SelectItem value={lang} key={lang} className="text-lg">
                        {lang.toUpperCase()}
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
            {/* <img src="/chronicle-color.svg" className="w-[90px] h-[95px]" /> */}
            <div className="w-[90px]">
              <IconLogo className="w-full h-auto" />
            </div>
            <div className="text-5xl text-gray-900 tracking-wide font-bold leading-none">
              Chronicle
            </div>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <h2 className="font-bold text-lg self-start">
              {t('login.reset_title')}
            </h2>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col gap-6 w-full"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <>
                      <FormItem>
                        <FormLabel>{t('login.email')}</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </>
                  )}
                />
                <Button type="submit" disabled={reset.isPending}>
                  {!reset.isPending && <span>{t('login.reset_button')}</span>}
                  {reset.isPending && (
                    <Loader2 className="animate-spin"></Loader2>
                  )}
                </Button>
              </form>
            </Form>
            <div className="flex justify-center">
              <NavLink to="/login">
                <Button
                  variant="link"
                  className="px-0 hover:no-underline hover:text-primary/80"
                >
                  {t('login.login')}
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-wrap items-center text-gray-400 gap-2 justify-center w-full text-sm">
          <a href="#" className="hover:underline">
            {t('login.terms_of_service')}
          </a>
          <Circle
            className="w-2 h-2 text-gray-300 hidden md:block lg:hidden xl:block"
            fill="currentColor"
          />
          <a href="#" className="hover:underline">
            {t('login.privacy_policy')}
          </a>
        </div> */}
      </div>
    </div>
  )
}
