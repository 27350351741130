import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom'
import RootLayout from './layouts/root-layout'
import ContentLayout from './layouts/content-layout'
import LoginPage from './pages/login'
import RegisterPage from './pages/register'
import ResetPage from './pages/reset'
import DashboardPage from './pages/dashboard'
import RequireAuth from './components/require-auth'
import RequireNoAuth from './components/require-no-auth'
import ErrorPage from './pages/error'
import ActivityPage from './pages/activity'
import DeliveryPage from './pages/delivery'
import SettingsPage from './pages/settings'
import SettingsIntegrations from './pages/settings/integrations'
import SettingsTeams from './pages/settings/teams'
import SettingsProjects from './pages/settings/projects'
import SettingsMappings from './pages/settings/mappings'
import ContributorActivityPage from './pages/activity/contributor'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route
        element={
          <RequireAuth>
            <ContentLayout />
          </RequireAuth>
        }
      >
        <Route index path="/" element={<Navigate to="activity" />} />
        <Route path="/activity" element={<ActivityPage />}></Route>
        <Route
          path="/activity/contributor/:contributorId"
          element={<ContributorActivityPage />}
        />
        <Route path="/dashboard" element={<DashboardPage />} />

        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/settings" element={<SettingsPage />}>
          <Route index element={<Navigate to="integrations" />} />
          <Route path="integrations" element={<SettingsIntegrations />} />
          <Route path="teams" element={<SettingsTeams />} />
          <Route path="projects" element={<SettingsProjects />} />
          <Route path="mappings" element={<SettingsMappings />} />
        </Route>
      </Route>
      <Route
        path="login"
        element={
          <RequireNoAuth>
            <LoginPage />
          </RequireNoAuth>
        }
      />
      <Route
        path="register"
        element={
          <RequireNoAuth>
            <RegisterPage />
          </RequireNoAuth>
        }
      />
      <Route
        path="reset"
        element={
          <RequireNoAuth>
            <ResetPage />
          </RequireNoAuth>
        }
      />
    </Route>
  )
)

export default router
