import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import IntegrationsForm from './integrations-form'
import { Loader2 } from 'lucide-react'
import { useGetIntegration } from '@/store/integrations'
import { useTranslation } from 'react-i18next'

export default function IntegrationsDialog(props: {
  id?: string
  open: boolean
  setOpen: (open: boolean) => void
}) {
  const { t } = useTranslation()
  const { data, isPending, isError } = useGetIntegration(props.id)

  function onSubmit() {
    props.setOpen(false)
  }

  return (
    <>
      <Dialog open={props.open} onOpenChange={props.setOpen}>
        <DialogTrigger asChild>
          <Button className="ml-auto">{t('settings.add_integration')}</Button>
        </DialogTrigger>
        <DialogContent className="max-w-3xl max-h-full overflow-auto">
          <DialogHeader>
            <DialogTitle>
              {props.id
                ? t('settings.edit_integration')
                : t('settings.add_new_integration')}
            </DialogTitle>
            {/* <DialogDescription>
              {props.id
                ? t('settings.edit_integration')
                : t('settings.add_new_integration')}
            </DialogDescription> */}
          </DialogHeader>
          {isPending || isError ? (
            <div className="flex h-full items-center justify-center py-24">
              <Loader2 className="animate-spin text-gray-400" />
            </div>
          ) : (
            <div>
              <IntegrationsForm integration={data} onSubmit={onSubmit} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
