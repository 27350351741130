import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'

export interface MappingsItem {
  id: string
  type: string
  attributes: {
    id: string
    integration_id: string
    name: string
    contributor_id: null | string
  }
}

export interface MappingsList {
  data: MappingsItem[]
  meta: {
    status: string
    total: number
  }
}

export const useMappingsList = (page = 1, perPage = 100) => {
  return useQuery({
    queryKey: ['mappings', page],
    queryFn: () => {
      return baseFetch<MappingsList, 'json'>('/contributor_names_map', {
        query: {
          page,
          page_size: perPage,
        },
      })
    },
  })
}

export const useUpdateMapping = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (attributes: Partial<MappingsItem['attributes']>) => {
      if (!attributes.id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: MappingsItem }, 'json'>(
        `/contributor_names_map/${attributes.id}`,
        {
          method: 'PUT',
          body: attributes,
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mappings'] })
    },
  })
}
