import { useRouteError } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function ErrorPage() {
  const { t } = useTranslation()
  const error = useRouteError() as { statusText?: string; message?: string }
  console.error(error)

  return (
    <div>
      <h1>{t('error.title')}</h1>
      <p>
        <i>{error?.statusText || error.message}</i>
      </p>
    </div>
  )
}
