function IconLogo(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="146"
      viewBox="0 0 139 146"
      fill="none"
      {...props}
    >
      <path
        d="M0.5 85.3456C0.5 75.8593 8.16322 68.1691 17.6163 68.1691C27.0693 68.1691 34.7326 75.8593 34.7326 85.3456V128.824C34.7326 138.31 27.0693 146 17.6163 146C8.16322 146 0.5 138.31 0.5 128.824V85.3456Z"
        fill="url(#paint0_linear_8849_230)"
      />
      <path
        d="M52.3837 107.89C52.3837 98.4034 60.0469 90.7132 69.5 90.7132C78.9531 90.7132 86.6163 98.4034 86.6163 107.89V128.824C86.6163 138.31 78.9531 146 69.5 146C60.0469 146 52.3837 138.31 52.3837 128.824V107.89Z"
        fill="url(#paint1_linear_8849_230)"
      />
      <path
        d="M104.267 59.5809C104.267 50.0946 111.931 42.4044 121.384 42.4044C130.837 42.4044 138.5 50.0946 138.5 59.5809V128.824C138.5 138.31 130.837 146 121.384 146C111.931 146 104.267 138.31 104.267 128.824V59.5809Z"
        fill="url(#paint2_linear_8849_230)"
      />
      <path
        d="M138.5 17.1765C138.5 26.6628 130.837 34.3529 121.384 34.3529C117.895 34.3529 114.65 33.3056 111.945 31.5072L84.4883 57.1842C85.8444 59.6447 86.6163 62.4745 86.6163 65.4853C86.6163 74.9716 78.9531 82.6618 69.5 82.6618C60.0469 82.6618 52.3837 74.9716 52.3837 65.4853C52.3837 64.3993 52.4842 63.3368 52.6762 62.3066L31.9084 52.396C28.846 57.0484 23.5883 60.1176 17.6163 60.1176C8.16322 60.1176 0.5 52.4275 0.5 42.9412C0.5 33.4549 8.16322 25.7647 17.6163 25.7647C27.0693 25.7647 34.7326 33.4549 34.7326 42.9412C34.7326 43.3693 34.7169 43.7938 34.6863 44.2141L56.3128 54.5345C59.4524 50.7314 64.1943 48.3088 69.5 48.3088C72.849 48.3088 75.9734 49.274 78.6119 50.9424L106.21 25.1324C104.969 22.7539 104.267 20.0474 104.267 17.1765C104.267 7.69017 111.931 0 121.384 0C130.837 0 138.5 7.69017 138.5 17.1765Z"
        fill="url(#paint3_linear_8849_230)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8849_230"
          x1="0.5"
          y1="0"
          x2="139.01"
          y2="145.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#304FFD" />
          <stop offset="1" stopColor="#FD7972" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8849_230"
          x1="0.5"
          y1="0"
          x2="139.01"
          y2="145.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#304FFD" />
          <stop offset="1" stopColor="#FD7972" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8849_230"
          x1="0.5"
          y1="0"
          x2="139.01"
          y2="145.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#304FFD" />
          <stop offset="1" stopColor="#FD7972" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8849_230"
          x1="0.5"
          y1="0"
          x2="139.01"
          y2="145.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#304FFD" />
          <stop offset="1" stopColor="#FD7972" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconLogo
