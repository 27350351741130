import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'

export interface RepositoryItem {
  id: string
  type: string
  attributes: {
    id: string
    integration_id: string
    description: string
    name: string
    avatar_url: string
    web_url: string
  }
}

export interface RepositoriesList {
  data: RepositoryItem[]
  meta: {
    status: string
    total: number
  }
}

export const useRepositoriesList = (
  page = 1,
  perPage = 100,
  selectFn?: (data: RepositoriesList) => any
) => {
  return useQuery({
    queryKey: ['repositories', page],
    queryFn: () => {
      return baseFetch<RepositoriesList, 'json'>('/repository', {
        query: {
          page,
          page_size: perPage,
        },
      })
    },
    select(data: RepositoriesList) {
      return selectFn ? selectFn(data) : data
    },
    placeholderData: keepPreviousData,
  })
}

export const useGetRepository = (id?: string) => {
  return useQuery({
    queryKey: ['repository', id ?? null],
    queryFn: async () => {
      if (!id) {
        return null
      }
      return (
        await baseFetch<{ data: RepositoryItem }, 'json'>(`/repository/${id}`)
      ).data
    },
  })
}
