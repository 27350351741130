import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'
// import { create } from 'zustand'
// interface TeamsState {
//   fetchAllMembers: () => Promise<void>
//   fetchTeams: () => Promise<void>
//   teams: TeamItem[]
//   members: ContributorItem[]
// }

enum ContributorState {
  Active = 'active',
  Blocked = 'blocked',
}

export interface ContributorItem {
  id: string
  integration_id: number
  username: string
  name: string
  state: ContributorState
  avatar_url: string
  web_url: string
}

export interface ContributorsList {
  data: ContributorItem[]
  meta: {
    status: string
    total: number
  }
}

export const useContributorsList = (
  page = 1,
  perPage = 100,
  selectFn?: (data: ContributorsList) => any
) => {
  return useQuery({
    queryKey: ['contributors', page],
    queryFn: () => {
      return baseFetch<ContributorsList, 'json'>('/contributors', {
        query: {
          page,
          page_size: perPage,
        },
      })
    },
    select(data: ContributorsList) {
      return selectFn ? selectFn(data) : data
    },
    placeholderData: keepPreviousData,
  })
}

export const useGetContributor = (id?: string) => {
  return useQuery({
    queryKey: ['contributor', id ?? null],
    queryFn: async () => {
      if (!id) {
        return null
      }
      return (
        await baseFetch<{ data: { attributes: ContributorItem } }, 'json'>(
          `/contributors/${id}`
        )
      ).data
    },
  })
}
