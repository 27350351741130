import { DataTable } from '@/components/ui/data-table'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import IntegrationsDialog from './components/integrations-dialog'
import IntegrationsDeleteDialog from './components/integrations-delete-dialog'
import {
  IntegrationItem,
  useIntegrationsList,
  useStartSync,
} from '@/store/integrations'
import { Loader2, Trash2, Pencil, RefreshCw } from 'lucide-react'
import { ColumnDef } from '@tanstack/react-table'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'

export default function SettingsTeams() {
  const { t, i18n } = useTranslation()
  const [
    page,
    // setPage
  ] = useState(0)
  const perPage = 100
  // const teams = useTeamsStore((state) => state.teams)
  // const fetchTeams = useTeamsStore((state) => state.fetchTeams)
  // const fetchAllMembers = useTeamsStore((state) => state.fetchAllMembers)
  const {
    isPending,
    isError,
    data,
    // error, isFetching, isPlaceholderData
  } = useIntegrationsList(page, perPage)

  const { toast } = useToast()

  // const hasMore = data?.meta?.total && data.meta.total < page * perPage

  function formatDate(date: string) {
    return new Intl.DateTimeFormat(i18n.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(date))
  }

  const onDetailsClick = (id: string) => {
    setIntegrationId(id)
    setOpen(true)
  }

  const onDeleteClick = (integration: IntegrationItem['attributes']) => {
    setToDeleteIntegration(integration)
    setDeleteOpen(true)
  }

  const startSync = useStartSync()

  const onRefreshClick = (id: string) => {
    startSync.mutate(id, {
      onSuccess() {
        toast({
          title: t('settings.sync_task_created_successfully'),
        })
      },
      onError() {
        toast({
          title: t('settings.sync_task_creation_failed'),
          variant: 'destructive',
        })
      },
    })
  }

  const [open, setOpen] = useState(false)
  const [openDelete, setDeleteOpen] = useState(false)
  const [integrationId, setIntegrationId] = useState<undefined | string>()
  const [toDeleteIntegration, setToDeleteIntegration] = useState<
    undefined | IntegrationItem['attributes']
  >()

  const actions = {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="flex gap-2">
          <Button
            size="icon"
            variant="ghost"
            className="w-9 h-9"
            onClick={() => onDetailsClick(row.original.attributes.id)}
          >
            <Pencil className="w-full h-auto p-2" />
          </Button>
          <Button
            size="icon"
            title={t('settings.sync')}
            variant="ghost"
            className="w-9 h-9"
            disabled={
              startSync.isPending ||
              row.original.attributes.last_sync_status === 'in_progress'
            }
            onClick={() => onRefreshClick(row.original.attributes.id)}
          >
            <RefreshCw
              className={
                'w-full h-auto p-2 transition-all' +
                (startSync.isPending ? ' animate-spin' : '')
              }
            />
          </Button>
          <Button
            size="icon"
            variant="ghost"
            className="w-9 h-9"
            onClick={() => onDeleteClick(row.original.attributes)}
          >
            <Trash2 className="w-full h-auto p-2" />
          </Button>
        </div>
      )
    },
  } as ColumnDef<IntegrationItem>
  const columns: ColumnDef<IntegrationItem>[] = [
    {
      accessorKey: 'attributes.name',
      header: t('settings.name'),
      cell: ({ row }) => {
        return (
          <Button
            variant="link"
            className="px-0 hover:no-underline hover:text-primary/80"
            onClick={() => onDetailsClick(row.original.attributes.id)}
          >
            {row.original.attributes.name}
          </Button>
        )
      },
    },
    {
      accessorKey: 'attributes.gitlab_endpoint',
      header: 'GitLab',
    },
    {
      accessorKey: 'attributes.created_at',
      header: t('settings.added'),
      cell: ({ row }) => {
        return <span>{formatDate(row.original.attributes.created_at)}</span>
      },
    },
    {
      accessorKey: 'attributes.last_sync_status',
      header: t('settings.sync'),
      cell: ({ row }) => {
        return (
          <div>
            <div
              className={
                row.original.attributes.sync_enabled
                  ? 'font-bold'
                  : 'text-red-800'
              }
            >
              {row.original.attributes.sync_enabled
                ? t('settings.enabled')
                : t('settings.disabled')}
            </div>
            {row.original.attributes.last_sync && (
              <div>
                {t('settings.updated')}:{' '}
                {formatDate(row.original.attributes.last_sync)}
              </div>
            )}
            {row.original.attributes.last_sync_status && (
              <div>
                {t('settings.status')}:{' '}
                {row.original.attributes.last_sync_status === 'failed' && (
                  <span className="text-red-800">
                    {row.original.attributes.last_sync_error}
                  </span>
                )}
                {row.original.attributes.last_sync_status === 'success' && (
                  <span className="text-green-600">
                    {t('settings.success')}
                  </span>
                )}
                {row.original.attributes.last_sync_status === 'in_progress' && (
                  <span className="text-yellow-800">
                    {t('settings.in_progress')}
                  </span>
                )}
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const changeDialogState = (open: boolean) => {
    setIntegrationId(undefined)
    setOpen(open)
  }

  const changeDeleteDialogState = (open: boolean) => {
    setToDeleteIntegration(undefined)
    setDeleteOpen(open)
  }

  return (
    <>
      <div className="text-right">
        <IntegrationsDialog
          open={open}
          setOpen={changeDialogState}
          id={integrationId}
        />
      </div>
      <div className="w-full h-full py-10">
        {isPending || isError ? (
          <div className="flex h-full items-center justify-center">
            <Loader2 className="animate-spin text-gray-400" />
          </div>
        ) : (
          <div>
            <DataTable columns={[...columns, actions]} data={data.data} />
          </div>
        )}
        {/* <span>Current Page: {page + 1}</span>
        <Button
          onClick={() => setPage((old) => Math.max(old - 1, 0))}
          disabled={page === 0}
        >
          Previous Page
        </Button>{' '}
        <Button
          onClick={() => {
            if (!isPlaceholderData && hasMore) {
              setPage((old) => old + 1)
            }
          }}
          // Disable the Next Page button until we know a next page is available
          disabled={isPlaceholderData || !hasMore}
        >
          Next Page
        </Button>
        {isFetching ? <span> Loading...</span> : null}{' '} */}
      </div>
      <IntegrationsDeleteDialog
        open={openDelete}
        setOpen={changeDeleteDialogState}
        integration={toDeleteIntegration}
      />
    </>
  )
}
