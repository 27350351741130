import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import TeamsForm from './teams-form'
import { Loader2 } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import {
  TeamItem,
  useCreateTeam,
  useGetTeam,
  useUpdateTeam,
} from '@/store/teams'
import { useTranslation } from 'react-i18next'

export default function IntegrationsDialog(props: {
  id?: string
  open: boolean
  setOpen: (open: boolean) => void
}) {
  const { toast } = useToast()
  const { data, isPending, isError } = useGetTeam(props.id)
  const update = useUpdateTeam(props.id)
  const create = useCreateTeam()
  const { t } = useTranslation()

  async function onFormSubmit(data: Partial<TeamItem['attributes']>) {
    if (props.id) {
      update.mutate(data, {
        onSuccess() {
          toast({
            title: t('settings.team_updated_successfully'),
          })
          props.setOpen(false)
        },
        onError() {
          toast({
            title: t('settings.failed_to_update_team'),
            variant: 'destructive',
          })
        },
      })
    } else {
      create.mutate(data, {
        onSuccess() {
          toast({
            title: t('settings.new_team_created_successfully'),
          })
          props.setOpen(false)
        },
        onError() {
          toast({
            title: t('settings.failed_to_create_new_team'),
            variant: 'destructive',
          })
        },
      })
    }
  }

  return (
    <>
      <Dialog open={props.open} onOpenChange={props.setOpen}>
        <DialogTrigger asChild>
          <Button className="ml-auto">{t('settings.add_team')}</Button>
        </DialogTrigger>
        <DialogContent className="max-w-3xl max-h-full overflow-auto">
          <DialogHeader>
            <DialogTitle>
              {props.id ? t('settings.edit_team') : t('settings.add_new_team')}{' '}
            </DialogTitle>
            {/* <DialogDescription>
              {props.id ? t('settings.edit_team') : t('settings.add_new_team')}{' '}
            </DialogDescription> */}
          </DialogHeader>
          {isPending || isError ? (
            <div className="flex h-full items-center justify-center py-24">
              <Loader2 className="animate-spin text-gray-400" />
            </div>
          ) : (
            <div>
              <TeamsForm
                team={data}
                onSubmit={onFormSubmit}
                isLoading={update.isPending || create.isPending}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
