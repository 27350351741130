import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import ProjectsForm from './projects-form'
import { Loader2 } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import {
  ProjectItem,
  useCreateProject,
  useGetProject,
  useUpdateProject,
} from '@/store/projects'
import { useTranslation } from 'react-i18next'

export default function ProjectsDialog(props: {
  id?: string
  open: boolean
  setOpen: (open: boolean) => void
}) {
  const { toast } = useToast()
  const { data, isPending, isError } = useGetProject(props.id)
  const update = useUpdateProject(props.id)
  const create = useCreateProject()
  const { t } = useTranslation()

  async function onFormSubmit(data: Partial<ProjectItem['attributes']>) {
    if (props.id) {
      update.mutate(data, {
        onSuccess() {
          toast({
            title: t('settings.project_updated_success'),
          })
          props.setOpen(false)
        },
        onError() {
          toast({
            title: t('settings.project_update_failed'),
            variant: 'destructive',
          })
        },
      })
    } else {
      create.mutate(data, {
        onSuccess() {
          toast({
            title: t('settings.project_created_success'),
          })
          props.setOpen(false)
        },
        onError() {
          toast({
            title: t('settings.project_create_failed'),
            variant: 'destructive',
          })
        },
      })
    }
  }

  return (
    <>
      <Dialog open={props.open} onOpenChange={props.setOpen}>
        <DialogTrigger asChild>
          <Button className="ml-auto">{t('settings.add_project')}</Button>
        </DialogTrigger>
        <DialogContent className="max-w-3xl max-h-full overflow-auto">
          <DialogHeader>
            <DialogTitle>
              {props.id
                ? t('settings.edit_project')
                : t('settings.add_new_project')}
            </DialogTitle>
            {/* <DialogDescription>
              {props.id
                ? t('settings.edit_project')
                : t('settings.add_new_project')}
            </DialogDescription> */}
          </DialogHeader>
          {isPending || isError ? (
            <div className="flex h-full items-center justify-center py-24">
              <Loader2 className="animate-spin text-gray-400" />
            </div>
          ) : (
            <div>
              <ProjectsForm
                project={data}
                onSubmit={onFormSubmit}
                isLoading={update.isPending || create.isPending}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
