import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Loader2 } from 'lucide-react'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { IntegrationItem, useCheckIntegration } from '@/store/integrations'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { ValidationError } from '@/types'
import {
  useCreateIntegration,
  useUpdateIntegration,
} from '@/store/integrations'

export default function IntegrationsForm(props: {
  integration: IntegrationItem | null
  onSubmit: () => void
}) {
  const update = useUpdateIntegration(props.integration?.id)
  const create = useCreateIntegration()
  const { toast } = useToast()
  const check = useCheckIntegration()
  const { t } = useTranslation()
  const formSchema = z.object({
    name: z.string().min(2, {
      message: t('settings.validation_integration_name'), // 'Название должно состоять минимум из двух символов',
    }),
    gitlab_endpoint: z.string().min(2, {
      message: t('settings.validation_integration_gitlab_endpoint'), // 'Ссылка должна содержать минимум из двух символов',
    }),
    gitlab_token: z.string().min(2, {
      message: t('settings.validation_integration_gitlab_token'), //'Токен должен состоять минимум из двух символов',
    }),
    gitlab_groups: z.string().optional(),
    gitlab_projects: z.string().optional(),
    sync_enabled: z.boolean(),
    sync_period: z.coerce
      .number()
      .min(1, { message: t('settings.validation_integration_sync_period') }), // 'Интервал должен быть больше нуля'
  })

  const {
    name,
    gitlab_endpoint,
    gitlab_token,
    gitlab_groups,
    gitlab_projects,
    sync_enabled,
    sync_period,
  } = props.integration?.attributes || {}

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: name || '',
      gitlab_endpoint: gitlab_endpoint || '',
      gitlab_token: gitlab_token || '',
      gitlab_groups: gitlab_groups || '',
      gitlab_projects: gitlab_projects || '',
      sync_enabled: sync_enabled || true,
      sync_period: sync_period ? sync_period : 24,
    },
  })

  type FormSchemaKeys = keyof z.infer<typeof formSchema>
  const keysArray: FormSchemaKeys[] = Object.keys(
    formSchema.shape
  ) as FormSchemaKeys[]

  function handleErrors(error: any) {
    if (
      error.response &&
      error.response.status === 422 &&
      error.response._data?.errors
    ) {
      const errorData = error.response._data.errors as ValidationError[]

      errorData.forEach((error) => {
        const fieldName = error.source.pointer
          .split(/[./]/)
          .pop() as FormSchemaKeys

        console.log('fieldName', fieldName)
        if (keysArray.includes(fieldName)) {
          form.setError(fieldName, {
            message: error.detail,
          })
        }
      })

      return true
    } else {
      return false
    }
  }

  const isLoading = update.isPending || create.isPending || false

  async function checkConnection() {
    check.mutate(form.getValues(), {
      onSuccess(data) {
        if (data?.status === 'success') {
          toast({
            title: t('settings.connection_success'),
            ...(data?.details && { description: data?.details }),
          })
        } else {
          toast({
            title: t('settings.connection_failed'),
            variant: 'destructive',
            ...(data?.details && { description: data?.details }),
          })
        }
      },
      onError() {
        toast({
          title: t('settings.connection_check_failed'),
          variant: 'destructive',
        })
      },
    })
  }

  async function onFormSubmit(data: Partial<IntegrationItem['attributes']>) {
    if (props.integration?.id) {
      update.mutate(data, {
        onSuccess() {
          toast({
            title: t('settings.integration_updated_successfully'),
          })
          props.onSubmit()
        },
        onError(error: any) {
          !handleErrors(error) &&
            toast({
              title: t('settings.integration_update_failed'),
              variant: 'destructive',
            })
        },
      })
    } else {
      create.mutate(data, {
        onSuccess() {
          toast({
            title: t('settings.new_integration_created_successfully'),
          })
          props.onSubmit()
        },
        onError(error: any) {
          !handleErrors(error) &&
            toast({
              title: t('settings.new_integration_creation_failed'),
              variant: 'destructive',
            })
        },
      })
    }
  }

  async function submit(values: z.infer<typeof formSchema>) {
    onFormSubmit({ ...values })
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="flex flex-col items-start gap-6 h-full"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <>
              <FormItem className="w-full max-w-[300px]">
                <FormLabel>{t('settings.name')}</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="gitlab_endpoint"
          render={({ field }) => (
            <>
              <FormItem className="w-full max-w-[300px]">
                <FormLabel>{t('settings.gitlab_endpoint')}</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="gitlab_token"
          render={({ field }) => (
            <>
              <FormItem className="w-full max-w-[300px]">
                <FormLabel>{t('settings.token')}</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="gitlab_projects"
          render={({ field }) => (
            <>
              <FormItem className="w-full max-w-[300px]">
                <FormLabel>{t('settings.gitlab_projects')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="project_link_one, project_link_two"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="gitlab_groups"
          render={({ field }) => (
            <>
              <FormItem className="w-full max-w-[300px]">
                <FormLabel>{t('settings.gitlab_groups')}</FormLabel>
                <FormControl>
                  <Input placeholder="group_one, group_two" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="sync_enabled"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center gap-2">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel className="text-sm font-normal">
                  {t('settings.auto_sync')}
                </FormLabel>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sync_period"
          render={({ field }) => (
            <>
              <FormItem className="w-full max-w-[300px]">
                <FormLabel>{t('settings.sync_period')}</FormLabel>
                <FormControl>
                  <Input type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <div className="mt-auto ml-auto flex gap-3 flex-wrap">
          <Button
            type="button"
            disabled={isLoading || !form.formState.isValid || check.isPending}
            onClick={checkConnection}
          >
            <span>{t('settings.check_connection')}</span>
            {check.isPending && (
              <Loader2 className="animate-spin ml-2"></Loader2>
            )}
          </Button>
          <Button type="submit" disabled={isLoading}>
            <span>{t('settings.save')}</span>
            {isLoading && <Loader2 className="animate-spin ml-2"></Loader2>}
          </Button>
        </div>
      </form>
    </Form>
  )
}
