import { useState, useEffect } from 'react'
import { Loader2 } from 'lucide-react'
import { ActivityList, useActivities } from '@/store/activities'
import { DateRange } from 'react-day-picker'
import { NavLink, useNavigate } from 'react-router-dom'
import { toDate } from 'date-fns-tz'
import { WeekPeaker } from '@/components/ui/week-picker'
import { startOfWeek, endOfWeek, format, addDays, getDay } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Input } from '@/components/ui/input'
import { useTeamsList } from '@/store/teams'
import { useProjectsList } from '@/store/projects'
// import { useRepositoriesList } from '@/store/repositories'
import { ContributorItem, useContributorsList } from '@/store/contributors'
import IconCommit from '@/components/icons/commit'
// import IconMergeCommit from '@/components/icons/merge-commit'
// import IconMRActivity from '@/components/icons/mr-activity'
// import IconMRComment from '@/components/icons/mr-comments'
import ActivityCommit from './activity/components/activity-commit'
import { useIntegrationsList } from '@/store/integrations'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'

export default function ActivityPage() {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [startDate, setStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  )
  const [endDate, setEndDate] = useState(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  )
  const [start, setStart] = useState(formatDate(startDate))
  const [end, setEnd] = useState(formatDate(endDate))
  const [team, setTeam] = useState<string>('all')
  const [project, setProject] = useState<string>('all')
  // const [repository, setRepository] = useState<string>('all')
  const [query, setQuery] = useState<string>('')
  const navigate = useNavigate()
  const { isFetched: isIntegrationsFetched, data: integrations } =
    useIntegrationsList(1, 10)

  useEffect(() => {
    if (isIntegrationsFetched && !integrations?.data.length) {
      toast({
        title: t('integration.no_integrations'),
        variant: 'warning',
        description: t('integration.no_integrations_description'),
        duration: 15000,
        action: (
          <ToastAction
            altText="Setup integrations"
            onClick={onSetupIntegrations}
          >
            {t('integration.setup_integration')}
          </ToastAction>
        ),
      })
    }
  }, [integrations, isIntegrationsFetched])

  function onSetupIntegrations() {
    navigate('/settings/integrations')
  }

  const {
    isPending: isTeamsPending,
    // isError: isTeamsError,
    data: teamsData,
  } = useTeamsList(0, 100)
  const {
    isPending: isProjectsPenging,
    // isError: isProjectsError,
    data: projectsData,
  } = useProjectsList(0, 100)

  // const {
  //   isPending: isRepositoriesPending,
  //   // isError: isProjectsPending,
  //   data: repositoriesData,
  // } = useRepositoriesList(0, 100)

  const {
    isPending: isContributorsPending,
    // isError: isContributorsError,
    data: contributorsData,
  } = useContributorsList(0, 3000)

  // function formatDate(date: string) {
  //   return new Intl.DateTimeFormat('ru-RU', {
  //     dateStyle: 'short',
  //     timeStyle: 'short',
  //   }).format(new Date(date))
  // }

  function formatDate(date: Date) {
    return format(toDate(date, { timeZone: 'UTC' }), 'yyyy-MM-dd')
  }

  const contributorsFilter =
    team !== 'all'
      ? teamsData?.data.find((item) => item.id === team)?.attributes
          .contributors || []
      : []

  const repositoriesFilter =
    project !== 'all'
      ? projectsData?.data.find((item) => item.id === project)?.attributes
          ?.repositories || []
      : []

  // if (repository !== 'all' && !repositoriesFilter.includes(repository)) {
  //   repositoriesFilter?.push(repository)
  // }
  const {
    isPending: isActivitiesPending,
    isError: isActivitiesError,
    data: activitiesData,
  } = useActivities(0, 1000, start, end, contributorsFilter, repositoriesFilter)

  function onSetDate(date: DateRange | undefined) {
    date?.from && setStart(formatDate(date.from))
    date?.to && setEnd(formatDate(date.to))
    setStartDate(date?.from as Date)
    setEndDate(date?.to as Date)
  }

  const transformData = (response: ActivityList) => {
    const activities: {
      [key: string]: { [key: string]: { commits: string[] } }
    } = {}

    response.data.forEach((item) => {
      const { contributor_id, date, commits } = item.attributes

      if (!activities[contributor_id]) {
        activities[contributor_id] = {}
      }

      if (!activities[contributor_id][date]) {
        activities[contributor_id][date] = { commits: [] }
      }

      activities[contributor_id][date].commits = commits
    })

    return activities
  }

  const activitiesMap = activitiesData ? transformData(activitiesData) : {}

  const contributors = (contributorsData?.data || []).filter(
    (item: { attributes: ContributorItem }) => {
      if (
        contributorsFilter.length &&
        !contributorsFilter.includes(item.attributes.id)
      ) {
        return false
      }

      if (query && !item.attributes.name.toLowerCase().includes(query)) {
        return false
      }

      if (!activitiesMap[item.attributes.id]) {
        return false
      }

      return true
    }
  )

  const customDayAbbreviations = [
    t('activity.sunday'),
    t('activity.monday'),
    t('activity.tuesday'),
    t('activity.wednesday'),
    t('activity.thursday'),
    t('activity.friday'),
    t('activity.saturday'),
  ]

  // Function to format the date with custom day abbreviation
  function customFormat(date: Date) {
    const dayOfWeek = getDay(date)
    const dayAbbreviation = customDayAbbreviations[dayOfWeek]
    const dayOfMonth = format(date, 'dd', { locale: ru })
    return `${dayAbbreviation} ${dayOfMonth}`
  }
  return (
    <>
      <div className="flex flex-col grow p-10 min-w-fit">
        <div className="font-bold text-3xl">{t('activity.title')}</div>
        <div className="w-full mt-8 bg-gray-50 grow flex flex-col">
          <div className="flex gap-6 pb-6 min-w-[1100px] w-full">
            <Input
              className="w-[180px]"
              placeholder={t('activity.search')}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Select onValueChange={setTeam} defaultValue={'all'}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t('activity.select_team')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t('activity.teams')}</SelectLabel>
                  <SelectItem value="all">{t('activity.all_teams')}</SelectItem>
                  {(teamsData?.data || []).map((team) => {
                    return (
                      <SelectItem value={team.id} key={team.id}>
                        {team.attributes.name}
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select onValueChange={setProject} defaultValue={'all'}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t('activity.select_project')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t('activity.projects')}</SelectLabel>
                  <SelectItem value="all">
                    {t('activity.all_projects')}
                  </SelectItem>
                  {(projectsData?.data || []).map((project) => {
                    return (
                      <SelectItem value={project.id} key={project.id}>
                        {project.attributes.name}
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
            {/* <Select onValueChange={setRepository} defaultValue={'all'}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t('activity.select_repository')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t('activity.repositories')}</SelectLabel>
                  <SelectItem value="all">
                    {t('activity.all_repositories')}
                  </SelectItem>
                  {(repositoriesData?.data || []).map((repository: any) => {
                    return (
                      <SelectItem value={repository.id} key={repository.id}>
                        {repository.attributes.name}
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select> */}
            <WeekPeaker
              from={startDate}
              to={endDate}
              onSetDate={onSetDate}
              className="ml-auto"
            />
          </div>

          <div className="flex gap-5 items-center pb-3 text-sm">
            <div className="flex gap-2 items-center">
              <IconCommit /> {t('activity.commits')}
            </div>
            {/* <div className="flex gap-2 items-center">
              <IconMergeCommit /> {t('activity.merge_commits')}
            </div>
            <div className="flex gap-2 items-center">
              <IconMRActivity /> {t('activity.mr_commits')}
            </div>
            <div className="flex gap-2 items-center">
              <IconMRComment /> {t('activity.mr_comments')}
            </div> */}
          </div>
          <div className="grow">
            {isActivitiesPending ||
            isActivitiesError ||
            isProjectsPenging ||
            isTeamsPending ||
            // isRepositoriesPending ||
            isContributorsPending ? (
              <div className="flex h-full items-center justify-center">
                <Loader2 className="animate-spin text-gray-400" />
              </div>
            ) : (
              <>
                <div className="min-w-[1100px] w-full">
                  <div className="flex gap-2 px-3 py-1 rounded-xl">
                    <div className="w-[25%]"></div>
                    {[...Array(7).keys()].map((i) => {
                      return (
                        <div
                          className="w-[10%] text-center text-gray-500"
                          key={i}
                        >
                          {customFormat(addDays(startDate, i))}
                        </div>
                      )
                    })}
                  </div>
                  <div className="flex flex-col w-full gap-3">
                    {contributors.length === 0 && (
                      <div className="text-center p-6 text-sm text-gray-700">
                        {t('ui.no_data')}{' '}
                      </div>
                    )}
                    {contributors.map(
                      (item: { attributes: ContributorItem }) => {
                        return (
                          <div
                            className="flex gap-2 px-3 py-3 rounded-xl bg-white "
                            key={item.attributes.id}
                          >
                            <div className="w-[25%] p-2">
                              <NavLink
                                to={`/activity/contributor/${item.attributes.id}`}
                                className="hover:opacity-80"
                              >
                                <div className="flex gap-3 items-center">
                                  <img
                                    src={item.attributes.avatar_url}
                                    className="w-[50px] h-[50px] rounded-full"
                                  />
                                  <div>{item.attributes.name}</div>
                                </div>
                              </NavLink>
                              <div className="text-sm mt-3 flex items-center gap-1">
                                <IconCommit className="text-blue-800" />
                                {Object.keys(
                                  activitiesMap[item.attributes.id] || {}
                                ).reduce(
                                  (acc, dateKey) =>
                                    acc +
                                    activitiesMap[item.attributes.id][dateKey]
                                      .commits.length,
                                  0
                                )}
                              </div>
                            </div>
                            {[...Array(7).keys()].map((i) => {
                              return (
                                <div
                                  className="w-[10%] border-l border-dashed px-2"
                                  key={i}
                                >
                                  <div className="flex gap-0.5 flex-wrap">
                                    {activitiesMap[item.attributes.id]?.[
                                      formatDate(addDays(startDate, i))
                                    ]?.commits?.map((commit) => {
                                      return (
                                        <ActivityCommit
                                          id={commit}
                                          key={commit}
                                        />
                                      )
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
