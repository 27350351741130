import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Loader2, X } from 'lucide-react'
import { z } from 'zod'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'

import { cn } from '@/lib/utils'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { ScrollArea } from '@/components/ui/scroll-area'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useState } from 'react'
import { ContributorItem, useContributorsList } from '@/store/contributors'
import { TeamItem } from '@/store/teams'
import { useTranslation } from 'react-i18next'

export default function TeamsForm(props: {
  team: TeamItem | null
  onSubmit: (data: Partial<TeamItem['attributes']>) => void
  isLoading?: boolean
}) {
  const { data } = useContributorsList(0, 3000)
  const { t } = useTranslation()

  const formSchema = z.object({
    name: z.string().min(1, {
      message: t('form.validation_required'),
    }),
    description: z.string().min(1, {
      message: t('form.validation_required'),
    }),
    contributors: z
      .array(z.string())
      .min(1, { message: t('settings.validation_teams_contributors') }),
  })

  const contributorsOptions = data.data.map(
    (contributor: { attributes: ContributorItem }) => ({
      value: contributor.attributes.id,
      label: contributor.attributes.name,
    })
  ) as { value: string; label: string }[]

  const { name, description, contributors } = props.team?.attributes || {}

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string[]>(
    contributors?.length ? [...contributors] : []
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: name || '',
      description: description || '',
      contributors: contributors || [],
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    props.onSubmit({ ...values, contributors: [...value] })
  }

  const ContributorOption = ({
    value,
    className,
  }: {
    value: string
    className?: string
  }) => {
    const contributor = data.data.find(
      (contributor: { attributes: ContributorItem }) =>
        contributor.attributes.id === value
    ).attributes

    return (
      <>
        <div className={cn('flex gap-3 items-center', className)}>
          <img src={contributor?.avatar_url} className="w-12 h-12" />
          <div>{contributor?.name}</div>
        </div>
      </>
    )
  }

  const [search, setSearch] = useState('')
  const filteredContributors = contributorsOptions.filter((item) =>
    item.label.toLowerCase().includes(search.toLowerCase())
  )
  const maxContributorsToDisplay = 30

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col items-start gap-6 h-full px-1"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <>
              <FormItem className="w-full">
                <FormLabel>{t('settings.name')}</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <>
              <FormItem className="w-full">
                <FormLabel>{t('settings.description')}</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </>
          )}
        />

        <FormField
          control={form.control}
          name="contributors"
          render={() => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-2 h-full">
                <FormLabel className="text-sm font-normal">
                  {t('settings.contributors')}
                </FormLabel>
                <Popover open={open} onOpenChange={setOpen} modal={true}>
                  <PopoverTrigger asChild>
                    <Button
                      type="button"
                      variant="outline"
                      role="combobox"
                      aria-expanded={open}
                      className="justify-between w-full"
                    >
                      {value.length
                        ? t('settings.selected_contributors', {
                            count: value.length,
                          })
                        : t('settings.select_contributors')}
                      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                    <Command>
                      <CommandInput
                        onValueChange={setSearch}
                        placeholder={t('settings.contributor_name_placeholder')}
                        className="h-9"
                      />
                      <CommandList>
                        <ScrollArea
                          className={
                            '[&>[data-radix-scroll-area-viewport]]:max-h-[300px]'
                          }
                        >
                          <CommandEmpty>
                            {t('settings.nothing_found')}
                          </CommandEmpty>
                          <CommandGroup>
                            {filteredContributors
                              .slice(0, maxContributorsToDisplay)
                              .map((option) => (
                                <CommandItem
                                  key={option.value}
                                  value={option.value}
                                  keywords={[option.label]}
                                  onSelect={(currentValue) => {
                                    const newValue = value.includes(
                                      option.value
                                    )
                                      ? [
                                          ...value.filter(
                                            (memberValue) =>
                                              memberValue !== currentValue
                                          ),
                                        ]
                                      : [...value, currentValue]
                                    form.setValue('contributors', newValue)
                                    setValue(newValue)
                                    setOpen(false)
                                  }}
                                >
                                  <ContributorOption value={option.value} />
                                  <CheckIcon
                                    className={cn(
                                      'ml-auto h-4 w-4',
                                      value.includes(option.value)
                                        ? 'opacity-100'
                                        : 'opacity-0'
                                    )}
                                  />
                                </CommandItem>
                              ))}
                          </CommandGroup>
                          {filteredContributors.length >
                            maxContributorsToDisplay && (
                            <div className="text-center text-gray-500 p-6 pt-4 text-sm">
                              {t('ui.display_n_of_m', {
                                n: maxContributorsToDisplay,
                                m: filteredContributors.length,
                              })}
                            </div>
                          )}
                        </ScrollArea>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormMessage />
                <ScrollArea className="mt-3 h-44">
                  <div className="flex flex-col gap-3 px-3">
                    {value.map((optionValue) => {
                      const contributor = data.data.find(
                        (contributor: { attributes: ContributorItem }) =>
                          contributor.attributes.id === optionValue
                      )?.attributes
                      return (
                        <div
                          key={contributor?.id}
                          className="flex gap-3 items-center"
                        >
                          <img
                            src={contributor?.avatar_url}
                            className="w-12 h-12"
                          />
                          <div>{contributor?.name}</div>
                          <Button
                            onClick={() =>
                              setValue(
                                value.filter((value) => optionValue !== value)
                              )
                            }
                            variant="destructive"
                            size="sm"
                            className="ml-auto"
                          >
                            <X className="w-5 h-5 mr-2" />{' '}
                            {t('settings.delete')}
                          </Button>
                        </div>
                      )
                    })}
                  </div>
                </ScrollArea>
              </div>
            </FormItem>
          )}
        />
        <div className="mt-auto ml-auto flex gap-3 flex-wrap">
          <Button type="submit" disabled={props.isLoading}>
            {!props.isLoading && <span>{t('settings.save')}</span>}
            {props.isLoading && <Loader2 className="animate-spin"></Loader2>}
          </Button>
        </div>
      </form>
    </Form>
  )
}
