import IconCommit from '@/components/icons/commit'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { useGetCommit } from '@/store/activities'
import { Loader2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export default function ActivityCommit(props: { id: string }) {
  const getCommit = useGetCommit()
  const { i18n } = useTranslation()

  function onOpenChange(open: boolean) {
    open && getCommit.mutate(props.id)
  }

  function formatDate(date: string) {
    return new Intl.DateTimeFormat(i18n.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(date))
  }

  return (
    <>
      <TooltipProvider delayDuration={200}>
        <Tooltip onOpenChange={onOpenChange}>
          <TooltipTrigger>
            <IconCommit className="w-[10px] h-[10px]" />
          </TooltipTrigger>
          <TooltipContent className="max-h-[300px] overflow-auto">
            {!getCommit.data ? (
              <div className="flex h-full items-center justify-center">
                <Loader2 className="animate-spin text-gray-400" />
              </div>
            ) : (
              <div className="w-[250px]">
                <div className="break-words font-[500] leading-tight hover:opacity-70">
                  <a
                    href={getCommit.data?.data.attributes.web_url}
                    target="_blank"
                  >
                    {getCommit.data?.data.attributes.message}
                  </a>
                </div>
                <div className="text-sm text-gray-400 mt-1">
                  {formatDate(
                    getCommit.data?.data.attributes.committed_date as string
                  )}
                </div>
              </div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  )
}
