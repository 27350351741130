import {
  useQuery,
  useInfiniteQuery,
  keepPreviousData,
  useMutation,
} from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'

export interface ActivityItem {
  id: string
  type: string
  attributes: {
    id: string
    contributor_id: string
    repository_id: string
    date: string
    commits: string[]
  }
}

export interface ActivityList {
  data: ActivityItem[]
  meta: {
    status: string | null
    total: number
  }
}

export interface CommitItem {
  id: string
  type: string
  attributes: {
    id: string
    contributor_id: string
    committed_date: string
    commit_id: string
    commit_short_id: string
    title: string
    message: string
    web_url: string
  }
}

export interface CommitList {
  data: CommitItem[]
  meta: {
    status: string | null
    total: number
  }
}

export const useActivities = (
  page = 1,
  perPage = 1000,
  start: string,
  end: string,
  contributors?: string[],
  repositories?: string[]
) => {
  return useQuery({
    queryKey: ['activities', page, start, end, contributors, repositories],
    queryFn: () => {
      return baseFetch<ActivityList, 'json'>('/activities', {
        query: {
          page,
          page_size: perPage,
          'filter[date_start]': start,
          'filter[date_end]': end,
          ...(contributors?.length && {
            'filter[contributor_in]': contributors,
          }),
          ...(repositories?.length && {
            'filter[repository_in]': repositories,
          }),
        },
      })
    },
    placeholderData: keepPreviousData,
  })
}

export interface ActiveDaysByWeek {
  value: number
  week: number
  date_start: string
  date_end: string
}

// /analytics/contributor/{contributor_id}/avg_commits_in_active_days_by_weeks

export const useActiveDaysByWeek = (
  start: string,
  end: string,
  contributor?: string
) => {
  return useQuery({
    queryKey: ['active_days_by_week', start, end, contributor],
    queryFn: () => {
      return baseFetch<ActiveDaysByWeek[], 'json'>(
        `/analytics/contributor/${contributor}/avg_active_days_by_weeks`,
        {
          query: {
            'filter[date_start]': start,
            'filter[date_end]': end,
          },
        }
      )
    },
  })
}

export const useCommitsInActiveDays = (
  start: string,
  end: string,
  contributor?: string
) => {
  return useQuery({
    queryKey: ['commits_in_active_days_by_week', start, end, contributor],
    queryFn: () => {
      return baseFetch<ActiveDaysByWeek[], 'json'>(
        `/analytics/contributor/${contributor}/avg_commits_in_active_days_by_weeks`,
        {
          query: {
            'filter[date_start]': start,
            'filter[date_end]': end,
          },
        }
      )
    },
  })
}

export const useGetCommits = (
  perPage: number,
  start: string,
  end: string,
  contributor?: string,
  repository?: string,
  commit?: string
) => {
  return useInfiniteQuery({
    queryKey: ['commits', perPage, contributor, start, end, repository, commit],
    queryFn: ({ pageParam }) => {
      return baseFetch<CommitList, 'json'>(`/commits`, {
        query: {
          page: pageParam,
          page_size: perPage,
          'filter[date_start]': start,
          'filter[date_end]': end,
          ...(repository && {
            'filter[repository_id]': repository,
          }),
          ...(contributor && {
            'filter[contributor_id]': contributor,
          }),
          ...(commit && {
            'filter[commit_id]': commit,
          }),
        },
      })
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (perPage * pages.length >= lastPage.meta.total) {
        return undefined
      }
      return lastPageParam + 1
    },
  })
}

export const useGetCommit = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      return await baseFetch<{ data: CommitItem }, 'json'>(`/commits/${id}`, {
        method: 'GET',
      })
    },
  })
}
