import { create } from 'zustand'
import { baseFetch } from '@/lib/base-fetch'
import { FetchError } from 'ofetch'
import { useMutation } from '@tanstack/react-query'

interface AuthState {
  token: string | null
  error: string | number | null | undefined
  login: (username: string, password: string) => Promise<void>
  logout: () => void
  resetStatus: () => void
  fetchUser: () => Promise<void>
  status: 'idle' | 'loading' | 'failed'
  user: UserResponse | null
}

interface UserResponse {
  attributes: {
    created_at: string
    email: string
    id: string
    is_enabled: boolean
    role: string
    username: string
  }
  id: string
  type: string
}

const storageToken = 'auth-token'
export const useAuthStore = create<AuthState>((set) => ({
  token: localStorage.getItem(storageToken) || null,
  status: 'idle',
  error: null,
  user: null,

  login: async (email, password) => {
    set({ status: 'loading', error: null, user: null })
    try {
      const response = await baseFetch<{ access_token: string }, 'json'>(
        '/users/token',
        {
          method: 'POST',
          body: new URLSearchParams({
            // token request is native maybe for http only cookie
            username: email,
            password,
          }),
        }
      )
      set({
        token: response.access_token,
        status: 'idle',
      })

      localStorage.setItem(storageToken, response.access_token)
    } catch (error: unknown) {
      if (error instanceof FetchError) {
        set({ error: error?.status, status: 'failed' })
      }
      throw error
    }
  },

  fetchUser: async () => {
    const { data } = await baseFetch<{ data: UserResponse }, 'json'>(
      '/users/me'
    )

    set({ user: data })
  },
  resetStatus: () => {
    set({ error: null, status: 'idle' })
  },
  logout: () => {
    localStorage.removeItem(storageToken)
    set({ token: null, error: null, user: null, status: 'idle' })
  },
}))

export interface AuthRegisterPayload {
  email: string
  password: string
}

export interface AuthResetPayload {
  email: string
}

export const useRegister = () => {
  return useMutation({
    mutationFn: async (attributes: AuthRegisterPayload) => {
      await baseFetch<{ data: unknown }, 'json'>('/users/register', {
        method: 'POST',
        body: attributes,
      })
    },
  })
}

export const useReset = () => {
  return useMutation({
    mutationFn: async (attributes: AuthResetPayload) => {
      await baseFetch<{ data: unknown }, 'json'>(
        '/users/reset_password_request',
        {
          method: 'POST',
          body: attributes,
        }
      )
    },
  })
}
