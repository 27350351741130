import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { CheckIcon } from 'lucide-react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils'
import { CaretSortIcon } from '@radix-ui/react-icons'
import { useTranslation } from 'react-i18next'

interface MappingsContributorSelectorProps {
  options: { value: string; label: string }[]
  onChange: (value: string | null) => any
  initialValue: string | null
}

const MappingsContributorSelector = ({
  options,
  onChange,
  initialValue,
}: MappingsContributorSelectorProps) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string | null>(initialValue)
  const { t } = useTranslation()

  return (
    <>
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between w-full"
          >
            {value
              ? options.find((option) => option.value === value)?.label
              : t('settings.select_contributor')}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
          <Command>
            <CommandInput
              placeholder={t('settings.contributor_name_placeholder')}
              className="h-9"
            />
            <CommandList>
              <ScrollArea
                className={
                  '[&>[data-radix-scroll-area-viewport]]:max-h-[300px]'
                }
              >
                <CommandEmpty>{t('settings.nothing_found')}</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    key="empty"
                    onSelect={() => {
                      setValue(null)
                      onChange(null)
                      setOpen(false)
                    }}
                  >
                    <span className="text-gray-500">
                      {t('settings.no_contributor')}
                    </span>
                  </CommandItem>
                  {options.map((option) => (
                    <CommandItem
                      key={option.value}
                      onSelect={() => {
                        setValue(option.value)
                        onChange(option.value)
                        setOpen(false)
                      }}
                    >
                      {option.label}
                      <CheckIcon
                        className={cn(
                          'ml-auto h-4 w-4',
                          value === option.value ? 'opacity-100' : 'opacity-0'
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default MappingsContributorSelector
