import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'

export interface ProjectItem {
  id: string
  type: string
  attributes: {
    id: string
    created_at: string
    name: string
    description: string
    repositories: string[]
  }
}

export interface ProjectsList {
  data: ProjectItem[]
  meta: {
    status: string
    total: number
  }
}

export const useProjectsList = (page = 1, perPage = 100) => {
  return useQuery({
    queryKey: ['projects', page],
    queryFn: () => {
      return baseFetch<ProjectsList, 'json'>('/projects', {
        query: {
          page,
          page_size: perPage,
        },
      })
    },
    placeholderData: keepPreviousData,
  })
}

export const useGetProject = (id?: string) => {
  return useQuery({
    queryKey: ['project', id ?? null],
    queryFn: async () => {
      if (!id) {
        return null
      }
      return (await baseFetch<{ data: ProjectItem }, 'json'>(`/projects/${id}`))
        .data
    },
  })
}

export const useUpdateProject = (id?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (attributes: Partial<ProjectItem['attributes']>) => {
      if (!id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: ProjectItem }, 'json'>(`/projects/${id}`, {
        method: 'PUT',
        body: attributes,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['project', id] })
      queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
  })
}

export const useDeleteProject = (id?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      if (!id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: ProjectItem }, 'json'>(`/projects/${id}`, {
        method: 'DELETE',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
  })
}

export const useCreateProject = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (attributes: Partial<ProjectItem['attributes']>) => {
      await baseFetch<{ data: ProjectItem }, 'json'>('/projects', {
        method: 'POST',
        body: attributes,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
  })
}
