import { Navigate } from 'react-router-dom'
import React from 'react'
import { useAuthStore } from '@/store/auth'

export default function RequireNoAuth({
  children,
}: {
  children: React.ReactNode
}) {
  const isAuthenticated = useAuthStore((state) => state.token)

  if (isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return children
}
