import { Outlet } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import IconDashboard from '@/components/icons/dashboard'
import IconPerformance from '@/components/icons/performance'
import IconCode from '@/components/icons/code'
import IconSettings from '@/components/icons/settings'
import { useAuthStore } from '@/store/auth'
import { ChevronDown } from 'lucide-react'
import { Button } from '@/components/ui/button'
// import IconLogoText from '@/components/icons/logo-text'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  // SelectLabel,
  SelectTrigger,
  // SelectValue,
} from '@/components/ui/select'

export default function ContentLayout() {
  const user = useAuthStore((state) => state.user)
  const fetchUser = useAuthStore((state) => state.fetchUser)
  const logout = useAuthStore((state) => state.logout)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    fetchUser()
  }, [fetchUser])
  const [sidebarHidden, setSidebarState] = useState(false)
  const menu = [
    {
      to: '/activity',
      text: t('menu.activity'),
      icon: <IconCode className="flex-shrink-0" />,
    },
    {
      to: '/dashboard',
      text: t('menu.dashboards'),
      disabled: true,
      icon: <IconDashboard className="flex-shrink-0" />,
    },
    {
      to: '/delivery',
      text: t('menu.delivery'),
      disabled: true,
      icon: <IconPerformance className="flex-shrink-0" />,
    },
    {
      to: '/settings',
      text: t('menu.settings'),
      icon: <IconSettings className="flex-shrink-0" />,
      className: 'mt-auto',
    },
  ]

  function onSidebarToggleClick() {
    setSidebarState((value) => !value)
  }

  return (
    <div className="content-layout flex h-screen w-full overflow-hidden">
      <div
        className={clsx([
          sidebarHidden ? 'w-[0px] invisible' : 'w-[280px]',
          'transition-all overflow-x-hidden duration-300 ease-in-out shrink-0',
        ])}
      >
        <div className="h-full w-[280px] bg-primary bg-gradient-to-b from-[hsl(220,71%,31%)] to-[hsl(226,46%,20%)] flex flex-col relative overflow-auto">
          <div className="p-8 px-9 pb-4">
            <div className="flex justify-start items-end gap-4 ">
              <img src="/chronicle-color.svg" className="h-10" />
              <div className="text-2xl text-white tracking-wider font-bold leading-none">
                Chronicle
              </div>
              {/* <div className="w-24 text-white -ml-[2px] pb-[2px]">
                <IconLogoText className="w-full h-auto" />
              </div> */}
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2 py-4 overflow-auto">
            {/* <button
              onClick={() =>
                i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')
              }
            >
              change lang
            </button> */}
            <div className="flex-1 flex flex-col gap-2 px-6">
              {menu.map((item) => (
                <NavLink
                  key={item.to}
                  to={item.to}
                  className={({ isActive }) =>
                    [
                      item.className,
                      isActive ? 'bg-white/20' : '',
                      item.disabled ? 'opacity-50 pointer-events-none' : '',
                      'flex flex-nowrap gap-4 items-center text-gray-100 px-3 py-3 hover:opacity-85 transition-opacity rounded-lg',
                    ].join(' ')
                  }
                >
                  {item.icon}
                  {item.text}
                </NavLink>
              ))}
            </div>
          </div>
          <hr className="" />
          <div className="flex gap-2 items-center text-gray-200 px-6 p1l-8 py-4 justify-between">
            {user ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="p-2 bg-transparent hover:bg-white/20 truncate">
                    <div className="truncate">{user?.attributes?.email}</div>
                    <ChevronDown className="ml-1.5 h-4 w-4 opacity-50" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="w-[232px]">
                  <DropdownMenuLabel>{t('login.account')}</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onSelect={logout}
                    className="cursor-pointer"
                  >
                    {t('login.logout')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <div className="mr-10 h-4 bg-gray-400/60 animate-pulse rounded-lg flex-grow"></div>
            )}

            <Select
              onValueChange={i18n.changeLanguage}
              defaultValue={i18n.language}
            >
              <SelectTrigger className="text-white border-none w-[4rem] shrink-0 bg-transparent">
                {i18n.language.toUpperCase()}
              </SelectTrigger>
              <SelectContent className="min-w-[6rem]">
                <SelectGroup>
                  {['en', 'ru'].map((lang) => {
                    return (
                      <SelectItem value={lang} key={lang}>
                        {lang.toUpperCase()}
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      <div className="flex-grow h-full w-full overflow-hidden relative">
        <div
          className="absolute left-0 top-1/2 z-40 group  py-8 px-1 -translate-y-1/2 hover:cursor-pointer"
          onClick={onSidebarToggleClick}
        >
          <div className="flex h-10 w-4 flex-col items-center">
            <div
              className={clsx([
                sidebarHidden ? '-rotate-12' : 'group-hover:rotate-12',
                'h-5 w-1 rounded-full bg-gray-400 group-hover:bg-gray-500 translate-y-0.5 transition-all',
              ])}
            ></div>
            <div
              className={clsx([
                sidebarHidden ? 'rotate-12' : 'group-hover:-rotate-12',
                'h-5 w-1 rounded-full bg-gray-400 group-hover:bg-gray-500 -translate-y-0.5 transition-all',
              ])}
            ></div>
          </div>
        </div>
        <div className="h-full bg-gray-50 overflow-auto flex flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
