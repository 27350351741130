import { useState } from 'react'
import { Loader2, ChevronLeft } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { ColumnDef } from '@tanstack/react-table'
import { DataTable } from '@/components/ui/data-table'
import {
  CommitItem,
  // ActiveDaysByWeek,
  useActiveDaysByWeek,
  useCommitsInActiveDays,
  useGetCommits,
} from '@/store/activities'
// import { TrendingUp } from 'lucide-react'
import {
  CartesianGrid,
  Line,
  LineChart,
  //  XAxis
} from 'recharts'
import {
  Card,
  CardContent,
  CardDescription,
  //   CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { DateRange } from 'react-day-picker'
import { toDate } from 'date-fns-tz'
// import { WeekPeaker } from '@/components/ui/week-picker'
import {
  startOfWeek,
  endOfWeek,
  format,
  //   subMonths,
  //   subQuarters,
  //   subYears,
  subWeeks,
  // addDays, getDay
} from 'date-fns'
import { ru } from 'date-fns/locale'
// import {
//   Select,
//   SelectContent,
//   SelectGroup,
//   SelectItem,
//   SelectLabel,
//   SelectTrigger,
//   SelectValue,
// } from '@/components/ui/select'
import { DatePickerWithRange } from '@/components/ui/date-range-picker'
import { useGetContributor } from '@/store/contributors'
// import { Input } from '@/components/ui/input'
// import { useTeamsList } from '@/store/teams'
// import { useProjectsList } from '@/store/projects'
// import { useRepositoriesList } from '@/store/repositories'
// import { ContributorItem, useContributorsList } from '@/store/contributors'
// import IconCommit from '@/components/icons/commit'
// import IconMergeCommit from '@/components/icons/merge-commit'
// import IconMRActivity from '@/components/icons/mr-activity'
// import IconMRComment from '@/components/icons/mr-comments'

enum TimeRangesKeys {
  Month = 'month',
  Quarter = 'quarter',
  HalfYear = 'halfYear',
  Year = 'year',
}
// const TimeRangesLabels = {
//   [TimeRangesKeys.Month]: 'Месяц',
//   [TimeRangesKeys.Quarter]: 'Квартал',
//   [TimeRangesKeys.HalfYear]: 'Пол года',
//   [TimeRangesKeys.Year]: 'Год',
// } as Record<TimeRangesKeys, string>

// const chartData = [
//   { month: 'January', desktop: 186, mobile: 80 },
//   { month: 'February', desktop: 305, mobile: 200 },
//   { month: 'March', desktop: 237, mobile: 120 },
//   { month: 'April', desktop: 73, mobile: 190 },
//   { month: 'May', desktop: 209, mobile: 130 },
//   { month: 'June', desktop: 214, mobile: 140 },
// ]

import { useTranslation } from 'react-i18next'

export default function ContributorActivityPage() {
  const { t, i18n } = useTranslation()
  const { contributorId } = useParams()
  const { start: periodStart, end: periodEnd } = getDatesByRange()
  const {
    isPending: isPendingContributor,
    isError: isErrorContributor,
    data: contributorData,
  } = useGetContributor(contributorId)

  const activeDaysChartConfig = {
    value: {
      label: t('activity.active_days_per_week'),
      color: 'hsl(var(--chart-1))',
    },
  } satisfies ChartConfig

  const commitsInActiveDaysChartConfig = {
    value: {
      label: t('activity.commits_per_active_day'),
      color: 'hsl(var(--chart-1))',
    },
  } satisfies ChartConfig

  //   data,
  //   error,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetching,
  //   isFetchingNextPage,
  //   status,

  const [startDate, setStartDate] = useState(periodStart)
  const [endDate, setEndDate] = useState(periodEnd)
  const [start, setStart] = useState(formatDate(startDate))
  const [end, setEnd] = useState(formatDate(endDate))
  //   const [team, setTeam] = useState<string>('all')
  //   const [project, setProject] = useState<string>('all')
  //   const [repository, setRepository] = useState<string>('all')
  //   const [query, setQuery] = useState<string>('')

  const {
    isPending: isPendingCommits,
    isError: isErrorCommits,
    data: commitsData,
    hasNextPage: hasNextCommitsPage,
    fetchNextPage: fetchNextCommitsPage,
    isFetchingNextPage: isNextCommitsPageFetching,
  } = useGetCommits(25, start, end, contributorId)

  const {
    isPending: isPedingCommitsInActiveDays,
    isError: isErrorCommitsInActiveDays,
    data: commitsInActiveDaysData,
  } = useCommitsInActiveDays(start, end, contributorId)

  const {
    isPending: isPedingActiveDaysByWeek,
    isError: isErrorActiveDaysByWeek,
    data: activeDaysByWeekData,
  } = useActiveDaysByWeek(start, end, contributorId)

  function getFormatParams() {
    return i18n.language === 'ru' ? { locale: ru } : {}
  }

  const activeDaysByWeekChartData = activeDaysByWeekData?.map((item) => ({
    value: item.value,
    date: `${format(item.date_start, 'PPP', getFormatParams())} — ${format(item.date_end, 'PPP', getFormatParams())}`,
  }))

  const commitsInActiveDaysChartData = commitsInActiveDaysData?.map((item) => ({
    value: item.value,
    date: `${format(item.date_start, 'PPP', getFormatParams())} — ${format(item.date_end, 'PPP', getFormatParams())}`,
  }))

  function formatDate(date: Date | string) {
    return format(toDate(date, { timeZone: 'UTC' }), 'yyyy-MM-dd')
  }

  function onSelectDate(date: DateRange | undefined) {
    if (date?.from && date?.to) {
      setStart(formatDate(date.from))
      setEnd(formatDate(date.to))
      setStartDate(date?.from as Date)
      setEndDate(date?.to as Date)
    }
  }

  function getDatesByRange(range?: TimeRangesKeys) {
    if (range === TimeRangesKeys.Month) {
      return {
        start: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 3),
        end: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }
    } else if (range === TimeRangesKeys.Quarter) {
      return {
        start: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 11),
        end: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }
    } else if (range === TimeRangesKeys.HalfYear) {
      return {
        start: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 23),
        end: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }
    } else if (range === TimeRangesKeys.Year) {
      return {
        start: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 47),
        end: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }
    }

    return {
      start: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 3),
      end: endOfWeek(new Date(), { weekStartsOn: 1 }),
    }
  }

  function formatCommitDate(date: string) {
    return new Intl.DateTimeFormat(i18n.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(date))
  }

  const columns: ColumnDef<CommitItem>[] = [
    {
      accessorKey: 'attributes.committed_date',
      header: t('activity.date'),
      cell: ({ row }) => {
        return (
          <span className="whitespace-nowrap">
            {' '}
            {formatCommitDate(row.original.attributes.committed_date)}
          </span>
        )
      },
    },
    {
      accessorKey: 'attributes.commit_short_id',
      header: t('activity.id'),
      cell: ({ row }) => {
        return (
          <a href={row.original.attributes.web_url} target="_blank">
            <Button
              variant="link"
              className="px-0 hover:no-underline hover:text-primary/80"
            >
              {row.original.attributes.commit_short_id}
            </Button>
          </a>
        )
      },
    },
    {
      accessorKey: 'attributes.message',
      header: t('activity.name'),
      cell: ({ row }) => {
        return row.original.attributes.message
      },
    },
  ]

  return (
    <>
      <div className="flex flex-col grow p-10 min-w-fit">
        <NavLink
          to="/activity"
          className="flex gap-0.5 items-center text-primary group hover:opacity-90 mr-auto"
        >
          <ChevronLeft className="w-5 group-hover:-translate-x-0.5 transition-all" />
          {t('activity.back')}
        </NavLink>

        <div className="w-full mt-3 bg-gray-50 grow flex flex-col">
          <div className="flex gap-6 pb-6 lg:justify-between flex-col lg:flex-row">
            <div>
              <div className="font-bold text-3xl">
                {t('activity.contributor_activity')}
              </div>
              {/* <div className="text-gray-600">
                {format(startDate, 'PPP', { locale: ru })} -{' '}
                {format(endDate, 'PPP', { locale: ru })}
              </div> */}
            </div>

            <DatePickerWithRange
              from={startDate}
              to={endDate}
              onSetDate={onSelectDate}
              className=""
            />
            {/* <Select onValueChange={setDates} defaultValue={'month'}>
              <SelectTrigger className="w-[320px]">
                <SelectValue placeholder="Выберите период" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Период</SelectLabel>
                  {Object.keys(TimeRangesLabels).map((key) => {
                    return (
                      <SelectItem key={key} value={key}>
                        {TimeRangesLabels[key as TimeRangesKeys]}
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select> */}
          </div>

          <div className="grow">
            {isPedingActiveDaysByWeek ||
            isErrorActiveDaysByWeek ||
            isPendingContributor ||
            isErrorContributor ||
            isPendingCommits ||
            isErrorCommits ||
            isPedingCommitsInActiveDays ||
            isErrorCommitsInActiveDays ? (
              <div className="flex h-full items-center justify-center">
                <Loader2 className="animate-spin text-gray-400" />
              </div>
            ) : (
              <div className="flex gap-3 w-full flex-wrap">
                <Card className="flex">
                  <CardContent className="flex flex-col pt-6 justify-center">
                    <a
                      href={contributorData?.attributes.web_url}
                      target="_blank"
                    >
                      <img
                        className="rounded-full w-[150px]"
                        src={contributorData?.attributes.avatar_url}
                        alt={contributorData?.attributes.name}
                      />
                    </a>
                    <a
                      className="mt-2 text-center font-bold"
                      href={contributorData?.attributes.web_url}
                      target="_blank"
                    >
                      {contributorData?.attributes.name}
                    </a>
                    <a
                      className="text-center text-gray-600 text-sm"
                      href={contributorData?.attributes.web_url}
                      target="_blank"
                    >
                      {contributorData?.attributes.username}
                    </a>
                  </CardContent>
                </Card>
                {/* <div className="flex p-3 rounded-xl bg-white w-full sm:w-full overflow-hidden"> */}
                <Card className="max-w-[400px] w-full">
                  <CardHeader>
                    <CardTitle>{t('activity.active_days')}</CardTitle>
                    {activeDaysByWeekChartData?.length && (
                      <CardDescription>
                        {t('activity.avg_active_days_per_week')}:{' '}
                        <strong>
                          {(
                            activeDaysByWeekChartData.reduce(
                              (sum, value) => sum + Number(value.value),
                              0
                            ) / activeDaysByWeekChartData.length
                          ).toFixed(1)}
                        </strong>
                      </CardDescription>
                    )}
                  </CardHeader>
                  <CardContent>
                    <ChartContainer config={activeDaysChartConfig}>
                      <LineChart
                        data={activeDaysByWeekChartData}
                        margin={{
                          top: 6,
                          left: 12,
                          right: 12,
                          bottom: 6,
                        }}
                      >
                        <CartesianGrid vertical={false} />
                        {/* <XAxis
                          dataKey="date_start"
                          padding={{ left: 30, right: 30 }}
                          tickLine={false}
                          axisLine={false}
                          tickMargin={8}
                        /> */}
                        {/* <XAxis
                          dataKey="date"
                          tickLine={false}
                          axisLine={false}
                          tickFormatter={() => ''}
                        /> */}
                        {/* <ChartTooltip content={<CustomTooltip />} /> */}
                        <ChartTooltip
                          cursor={false}
                          content={
                            <ChartTooltipContent
                              indicator="line"
                              hideIndicator
                              labelKey="date"
                            />
                          }
                        />
                        <Line
                          dataKey="value"
                          type="bump"
                          stroke="var(--color-value)"
                          strokeWidth={2}
                          dot={{
                            fill: 'var(--color-value)',
                          }}
                          activeDot={{
                            r: 6,
                          }}
                        />
                      </LineChart>
                    </ChartContainer>
                  </CardContent>
                  {/* <CardFooter className="flex-col items-start gap-2 text-sm">
                      <div className="flex gap-2 font-medium leading-none">
                        Trending up by 5.2% this month{' '}
                        <TrendingUp className="h-4 w-4" />
                      </div>
                      <div className="leading-none text-muted-foreground">
                        Showing total visitors for the last 6 months
                      </div>
                    </CardFooter> */}
                </Card>
                <Card className="max-w-[400px] w-full">
                  <CardHeader>
                    <CardTitle>
                      {t('activity.commits_per_active_days')}
                    </CardTitle>
                    {commitsInActiveDaysChartData?.length && (
                      <CardDescription>
                        {t('activity.avg_commits_per_active_days_per_week')}:{' '}
                        <strong>
                          {(
                            commitsInActiveDaysChartData.reduce(
                              (sum, value) => sum + Number(value.value),
                              0
                            ) / commitsInActiveDaysChartData.length
                          ).toFixed(1)}
                        </strong>
                      </CardDescription>
                    )}
                  </CardHeader>
                  <CardContent>
                    <ChartContainer config={commitsInActiveDaysChartConfig}>
                      <LineChart
                        data={commitsInActiveDaysChartData}
                        margin={{
                          top: 6,
                          left: 12,
                          right: 12,
                          bottom: 6,
                        }}
                      >
                        <CartesianGrid vertical={false} />
                        <ChartTooltip
                          cursor={false}
                          content={
                            <ChartTooltipContent
                              indicator="line"
                              hideIndicator
                              labelKey="date"
                            />
                          }
                        />
                        <Line
                          dataKey="value"
                          type="bump"
                          stroke="var(--color-value)"
                          strokeWidth={2}
                          dot={{
                            fill: 'var(--color-value)',
                          }}
                          activeDot={{
                            r: 6,
                          }}
                        />
                      </LineChart>
                    </ChartContainer>
                  </CardContent>
                </Card>
                <div className="w-full mt-8">
                  <h3 className="text-xl font-semibold leading-none tracking-tight mb-5">
                    {t('activity.commits_in_period')}
                  </h3>
                  <DataTable
                    columns={[...columns]}
                    data={(commitsData?.pages || []).map((v) => v.data).flat()}
                  />
                  {isNextCommitsPageFetching && (
                    <div className="flex py-6 items-center justify-center">
                      <Loader2 className="animate-spin text-gray-400" />
                    </div>
                  )}
                  {!isNextCommitsPageFetching && hasNextCommitsPage && (
                    <div className="flex justify-center w-full">
                      <Button
                        variant="link"
                        className="px-0 hover:no-underline hover:text-primary/80"
                        onClick={() => fetchNextCommitsPage()}
                      >
                        {t('activity.load_more')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
