import { DataTable } from '@/components/ui/data-table'
import { useMemo } from 'react'
import {
  MappingsItem,
  useMappingsList,
  useUpdateMapping,
} from '@/store/mappings'
import { Loader2 } from 'lucide-react'
import { ColumnDef } from '@tanstack/react-table'
import { useToast } from '@/components/ui/use-toast'
import { useIntegrationsList } from '@/store/integrations'
import { ContributorItem, useContributorsList } from '@/store/contributors'
import MappingsContributorSelector from './components/mappings-contributor-selector'
import { useTranslation } from 'react-i18next'

export default function SettingsMappings() {
  const { t } = useTranslation()
  const { isPending, isError, data } = useMappingsList(1, 3000)

  const {
    isPending: isIntegrationsPending,
    isError: isIntegrationsError,
    data: integrations,
  } = useIntegrationsList(1, 1000)

  const {
    isPending: isContributorsPending,
    isError: isContributorsError,
    data: contributors,
  } = useContributorsList(1, 3000)

  const updateMapping = useUpdateMapping()

  const contributorsOptions = useMemo(
    () => [
      ...((contributors?.data || []).map(
        (contributor: { attributes: ContributorItem }) => ({
          value: contributor.attributes.id,
          label: contributor.attributes.name,
        })
      ) as { value: string; label: string }[]),
    ],
    [contributors?.data]
  )

  const { toast } = useToast()

  const handleContributorChange = (value: string | null, nameId: string) => {
    updateMapping.mutate(
      { id: nameId, contributor_id: value },
      {
        onSuccess() {
          toast({
            title: t('settings.mapping_updated_successfully'),
          })
        },
        onError() {
          toast({
            title: t('settings.mapping_update_failed'),
            variant: 'destructive',
          })
        },
      }
    )
  }

  function IntegrationName({ id }: { id: string }) {
    const integration = integrations?.data.find(
      (integration) => integration.id === id
    )

    if (integration) {
      return <>{integration.attributes.name}</>
    }

    return <>{id}</>
  }

  const columns: ColumnDef<MappingsItem>[] = useMemo(
    () => [
      {
        accessorKey: 'attributes.name',
        header: t('settings.contributor_name'),
        cell: ({ row }) => {
          return <span>{row.original.attributes.name}</span>
        },
      },
      {
        accessorKey: 'attributes.integration_id',
        header: t('settings.integration'),
        cell: ({ row }) => {
          return (
            <span>
              <IntegrationName id={row.original.attributes.integration_id} />
            </span>
          )
        },
      },
      {
        accessorKey: 'attributes.contributor_id',
        header: t('settings.contributor'),
        cell: ({ row }) => {
          return (
            <>
              <MappingsContributorSelector
                key={row.original.attributes.id}
                options={contributorsOptions}
                onChange={(value: string | null) =>
                  handleContributorChange(value, row.original.attributes.id)
                }
                initialValue={row.original.attributes.contributor_id}
              />
            </>
          )
        },
      },
    ],
    [contributorsOptions]
  )

  return (
    <>
      <div className="w-full h-full">
        <div className="mb-6 text-gray-500">
          {t('settings.table_description')}
        </div>
        {isPending ||
        isError ||
        isIntegrationsPending ||
        isIntegrationsError ||
        isContributorsPending ||
        isContributorsError ? (
          <div className="flex h-full items-center justify-center">
            <Loader2 className="animate-spin text-gray-400" />
          </div>
        ) : (
          <div>
            <DataTable columns={[...columns]} data={data.data} />
          </div>
        )}
      </div>
    </>
  )
}
