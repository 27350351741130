import { ofetch, FetchRequest, FetchOptions, FetchError } from 'ofetch'
import { useAuthStore } from '@/store/auth'

// <T = any, R extends ResponseType = "json">(request: FetchRequest, options?: FetchOptions<R>): Promise<MappedType<R, T>>;
// copied from ofetch types
interface ResponseMap {
  blob: Blob
  text: string
  arrayBuffer: ArrayBuffer
  stream: ReadableStream<Uint8Array>
}
type ResponseType = keyof ResponseMap | 'json'

const baseFetch = async <T, R extends ResponseType>(
  request: FetchRequest,
  options?: FetchOptions<R>
) => {
  const token = useAuthStore.getState().token
  const logout = useAuthStore.getState().logout

  try {
    const data = await ofetch<T, R>(request, {
      baseURL: import.meta.env.VITE_API_URL,
      ...(options || {}),
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
        ...options?.headers,
      },
    })
    return data
  } catch (error: unknown) {
    if (
      error instanceof FetchError &&
      error.status === 401 &&
      token &&
      logout
    ) {
      logout()
      throw error
    } else {
      throw error
    }
  }
}

export { baseFetch }
