import { DataTable } from '@/components/ui/data-table'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Loader2, Trash2, Pencil } from 'lucide-react'
import { TeamItem, useTeamsList } from '@/store/teams'
import { ColumnDef } from '@tanstack/react-table'
import {
  ContributorItem,
  ContributorsList,
  useContributorsList,
} from '@/store/contributors'
import TeamsDialog from './components/teams-dialog'
import TeamsDeleteDialog from './components/teams-delete-dialog'
import { useTranslation } from 'react-i18next'

export default function SettingsTeams() {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openDelete, setDeleteOpen] = useState(false)
  const [teamId, setTeamId] = useState<undefined | string>()
  const [toDeleteTeam, setToDeleteTeam] = useState<
    undefined | TeamItem['attributes']
  >()

  const { isPending, isError, data } = useTeamsList(0, 100)

  function selector(data: ContributorsList) {
    return data.data.reduce(
      (acc, contributor) => {
        acc[contributor.id] = contributor
        return acc
      },
      {} as Record<string, ContributorItem>
    )
  }

  const {
    isPending: isContributorsPending,
    isError: isContributorsError,
    data: contributorsMap,
  } = useContributorsList(0, 3000, selector)

  function onDetailsClick(id: string) {
    setTeamId(id)
    setOpen(true)
  }

  function onDeleteClick(team: TeamItem['attributes']) {
    setToDeleteTeam(team)
    setDeleteOpen(true)
  }

  function formatDate(date: string) {
    return new Intl.DateTimeFormat(i18n.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(date))
  }

  const actions = {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="flex gap-2">
          <Button
            size="icon"
            variant="ghost"
            className="w-9 h-9"
            onClick={() => onDetailsClick(row.original.attributes.id)}
          >
            <Pencil className="w-full h-auto p-2" />
          </Button>
          <Button
            size="icon"
            variant="ghost"
            className="w-9 h-9"
            onClick={() => onDeleteClick(row.original.attributes)}
          >
            <Trash2 className="w-full h-auto p-2" />
          </Button>
        </div>
      )
    },
  } as ColumnDef<TeamItem>

  const columns: ColumnDef<TeamItem>[] = [
    {
      accessorKey: 'attributes.name',
      header: t('settings.name'),
      cell: ({ row }) => {
        return (
          <Button
            variant="link"
            className="px-0 hover:no-underline hover:text-primary/80"
            onClick={() => onDetailsClick(row.original.attributes.id)}
          >
            {row.original.attributes.name}
          </Button>
        )
      },
    },
    {
      accessorKey: 'attributes.description',
      header: t('settings.description'),
    },
    {
      accessorKey: 'attributes.contributors',
      header: t('settings.contributors'),
      cell: ({ row }) => {
        const contributors = row.original.attributes.contributors
        return contributors.map((id: string, index: number) => (
          <span key={id}>
            {index ? ', ' : ''}
            {contributorsMap[id]?.attributes.name || id}
          </span>
        ))
      },
    },
    {
      accessorKey: 'attributes.created_at',
      header: t('settings.created_at'),
      cell: ({ row }) => {
        return <span>{formatDate(row.original.attributes.created_at)}</span>
      },
    },
  ]

  const changeDialogState = (open: boolean) => {
    setTeamId(undefined)
    setOpen(open)
  }

  const changeDeleteDialogState = (open: boolean) => {
    setToDeleteTeam(undefined)
    setDeleteOpen(open)
  }

  return (
    <>
      <div className="text-right">
        <TeamsDialog open={open} setOpen={changeDialogState} id={teamId} />
      </div>
      <div className="w-full h-full py-10">
        {isPending ||
        isError ||
        isContributorsPending ||
        isContributorsError ? (
          <div className="flex h-full items-center justify-center">
            <Loader2 className="animate-spin text-gray-400" />
          </div>
        ) : (
          <div>
            <DataTable columns={[...columns, actions]} data={data.data} />
          </div>
        )}
      </div>
      <TeamsDeleteDialog
        open={openDelete}
        setOpen={changeDeleteDialogState}
        team={toDeleteTeam}
      />
    </>
  )
}
