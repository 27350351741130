import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { baseFetch } from '@/lib/base-fetch'
// import { create } from 'zustand'
// interface TeamsState {
//   fetchAllMembers: () => Promise<void>
//   fetchTeams: () => Promise<void>
//   teams: TeamItem[]
//   members: ContributorItem[]
// }
// import type { ContributorItem } from './contributors'

export interface TeamItem {
  id: string
  type: string
  attributes: {
    id: string
    name: string
    created_at: string
    description: string
    contributors: string[]
  }
}

export interface TeamsList {
  data: TeamItem[]
  meta: {
    status: string
    total: number
  }
}

export const useTeamsList = (page = 1, perPage = 100) => {
  return useQuery({
    queryKey: ['teams', page],
    queryFn: () => {
      return baseFetch<TeamsList, 'json'>('/teams', {
        query: {
          page,
          page_size: perPage,
        },
      })
    },
    placeholderData: keepPreviousData,
  })
}

export const useGetTeam = (id?: string) => {
  return useQuery({
    queryKey: ['team', id ?? null],
    queryFn: async () => {
      if (!id) {
        return null
      }
      return (await baseFetch<{ data: TeamItem }, 'json'>(`/teams/${id}`)).data
    },
  })
}

export const useUpdateTeam = (id?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (attributes: Partial<TeamItem['attributes']>) => {
      if (!id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: TeamItem }, 'json'>(`/teams/${id}`, {
        method: 'PUT',
        body: attributes,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['team', id] })
      queryClient.invalidateQueries({ queryKey: ['teams'] })
    },
  })
}

export const useDeleteTeam = (id?: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      if (!id) {
        throw new Error('Illecal mutate call without id')
      }
      return baseFetch<{ data: TeamItem }, 'json'>(`/teams/${id}`, {
        method: 'DELETE',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] })
    },
  })
}

export const useCreateTeam = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (attributes: Partial<TeamItem['attributes']>) => {
      await baseFetch<{ data: TeamItem }, 'json'>('/teams', {
        method: 'POST',
        body: attributes,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] })
    },
  })
}

// export const useTeamsStore = create<TeamsState>((set) => ({
//   teams: [],
//   members: [],

//   fetchAllMembers: async () => {
//     await set({
//       members: [
//         {
//           id: 1,
//           integration: 1,
//           username: 'joe1',
//           name: 'John Week 1',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 2,
//           integration: 1,
//           username: 'joe2',
//           name: 'John Week 2',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 3,
//           integration: 1,
//           username: 'joe3',
//           name: 'John Week 3',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 4,
//           integration: 1,
//           username: 'joe4',
//           name: 'John Week 4',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 5,
//           integration: 1,
//           username: 'joe5',
//           name: 'John Week 5',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 6,
//           integration: 1,
//           username: 'joe6',
//           name: 'John Week 6',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 7,
//           integration: 1,
//           username: 'joe7',
//           name: 'John Week 7',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 8,
//           integration: 1,
//           username: 'joe8',
//           name: 'John Week 8',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 9,
//           integration: 1,
//           username: 'joe9',
//           name: 'John Week 9',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//         {
//           id: 10,
//           integration: 1,
//           username: 'joe10',
//           name: 'John Week 10',
//           state: CContributorState.Active,
//           avatar_url: 'https://avatar.iran.liara.run/public',
//           web_url: 'https://avatar.iran.liara.run/public',
//         },
//       ],
//     })
//   },
//   fetchTeams: async () => {
//     // await new Promise((resolve) => setTimeout(() => resolve(true), 2000))
//     await set({
//       teams: [
//         {
//           id: 1,
//           name: 'Best Team',
//           description: 'Very best team ever here',
//           contributors: [
//             {
//               id: 1,
//               integration: 1,
//               username: 'joe1',
//               name: 'John Week 1',
//               state: CContributorState.Active,
//               avatar_url: 'https://avatar.iran.liara.run/public',
//               web_url: 'https://avatar.iran.liara.run/public',
//             },
//             {
//               id: 2,
//               integration: 1,
//               username: 'joe2',
//               name: 'John Week 2',
//               state: CContributorState.Active,
//               avatar_url: 'https://avatar.iran.liara.run/public',
//               web_url: 'https://avatar.iran.liara.run/public',
//             },
//           ],
//         },
//       ],
//     })
//   },
//   addTeam: (team: CTeam) => {
//     set((state) => ({ teams: [...state.teams, team] }))
//   },
// }))
