'use client'

import * as React from 'react'
import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import { DateRange } from 'react-day-picker'
import { ru } from 'date-fns/locale'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useTranslation } from 'react-i18next'

export function DatePickerWithRange({
  className,
  from,
  to,
  onSetDate,
}: React.HTMLAttributes<HTMLDivElement> & {
  from: Date
  to: Date
  onSetDate: (date: DateRange | undefined) => any
}) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from,
    to,
  })

  const { i18n, t } = useTranslation()

  function setDateRange(date: DateRange | undefined) {
    onSetDate(date)
    setDate(date)
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4 shrink-0" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(
                    date.from,
                    'PPP',
                    i18n.language === 'ru' ? { locale: ru } : {}
                  )}{' '}
                  -{' '}
                  {format(
                    date.to,
                    'PPP',
                    i18n.language === 'ru' ? { locale: ru } : {}
                  )}
                </>
              ) : (
                format(
                  date.from,
                  'PPP',
                  i18n.language === 'ru' ? { locale: ru } : {}
                )
              )
            ) : (
              <span>{t('ui.select_date')}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            locale={i18n.language === 'ru' ? ru : undefined}
            weekStartsOn={1}
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDateRange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
