import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Loader2 } from 'lucide-react'
import { IntegrationItem, useDeleteIntegration } from '@/store/integrations'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'

export default function IntegrationsDialog(props: {
  integration?: IntegrationItem['attributes']
  open: boolean
  setOpen: (open: boolean) => void
}) {
  const { t } = useTranslation()
  const { toast } = useToast()
  const remove = useDeleteIntegration(props.integration?.id)

  async function onDelete() {
    remove.mutate(undefined, {
      onSuccess() {
        toast({
          title: t('settings.integration_deleted_successfully'),
        })
        props.setOpen(false)
      },
      onError() {
        toast({
          title: t('settings.integration_deletion_failed'),
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <>
      <Dialog open={props.open} onOpenChange={props.setOpen}>
        <DialogContent className="max-w-xl max-h-full overflow-auto">
          <DialogHeader>
            <DialogTitle>
              {t('settings.delete_integration')} {props.integration?.name}
            </DialogTitle>
            <DialogDescription>
              {t('settings.deletion_warning')}
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-between mt-8">
            <Button onClick={() => props.setOpen(false)}>
              {t('settings.cancel')}
            </Button>
            <Button
              disabled={remove.isPending}
              variant="destructive"
              onClick={onDelete}
            >
              {!remove.isPending && <span>{t('settings.delete')}</span>}
              {remove.isPending && <Loader2 className="animate-spin"></Loader2>}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
